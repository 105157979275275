<template>
  <div class="gang-up-container">
    <div class="context-container">
      <div class="left-container">
        <h3>
          <img src="@/assets/img/kh_icon.png" alt="" />
          蚂蚁特权官方玩家开黑福利群（微信群）
        </h3>
        <p>
          <img src="@/assets/img/gb_icon.png" alt="" />
          进入游戏详情-选择开黑大厅-扫码加群
        </p>
        <p>
          <img src="@/assets/img/gb_icon.png" alt="" />
          进群可免费获得加速器激活码、现金红包、影视会员、蚂蚁特权会员、游戏周边等好礼
        </p>
      </div>
      <div class="right-container" @click="showDialog(1, null)">
        <img src="@/assets/img/kf_icon.png" alt="" />
      </div>
    </div>
    <div class="group-container">
      <div class="title">游戏开黑群</div>
      <el-row :gutter="12">
        <el-col :span="8" v-for="(item, index) in gameGroup" :key="index">
          <div class="group-item">
            <div>
              <img
                :src="item.Img2 ? item.Img2 : require('@/assets/img/khq.png')"
                width="60"
                height="60"
                alt=""
              />
              {{ item.Name }}
            </div>
            <el-button type="primary" @click="showDialog(2, item)"
              >入群</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="group-container">
      <div class="title">
        <span>城市开黑群</span>
        <div class="input-container">
          <el-input v-model="keyCity" placeholder="请输入城市" clearable />
        </div>
      </div>
      <el-row :gutter="12">
        <el-col
          :span="8"
          v-for="(item, index) in filterCcityGroup"
          :key="index"
        >
          <div class="group-item">
            <div>
              <img
                :src="item.Img2 ? item.Img2 : require('@/assets/img/khq.png')"
                width="60"
                height="60"
                alt=""
              />
              {{ item.Name }}
            </div>
            <el-button type="primary" @click="showDialog(2, item)"
              >入群</el-button
            >
          </div>
        </el-col>
      </el-row>
      <div
        class="no-data"
        v-if="filterCcityGroup.length == 0"
        @click="showDialog(1, null)"
      >
        <img src="@/assets/img/noData.png" alt />
        <p>抱歉，没有找到您想要的群，<span>去申请添加新城市</span></p>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="280PX"
      custom-class="custom-dialog"
      :close-on-click-modal="false"
      :title="title"
      center
    >
      <img :src="qrCodeUrl" width="220" alt="" />
      <div class="text">
        <i class="el-icon-full-screen"></i>{{ textContent }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetUserWeChatGroup } from "@/api";

export default {
  computed: {
    filterCcityGroup: function () {
      if (this.keyCity) {
        return this.cityGroup.filter(
          (item) => item.Name.indexOf(this.keyCity) != -1
        );
      } else {
        return this.cityGroup;
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      qrCodeUrl: "",
      title: "",
      gameGroup: [],
      cityGroup: [],
      keyCity: "",
      textContent: "请用微信扫码进群"
    };
  },
  mounted() {
    this._GetUserWeChatGroup();
  },
  methods: {
    async _GetUserWeChatGroup() {
      let params = {
        gameName: "全部",
      };
      let res = await GetUserWeChatGroup(params);
      if (res.StatusCode == 200) {
        console.log("res", res);
        let data = res.Object;
        data.length &&
          data.map((item) => {
            if (item.Type == 1) {
              this.cityGroup.push(item);
            } else {
              this.gameGroup.push(item);
            }
          });
      } else {
        this.$message.error(res.Message);
      }
    },
    showDialog(i, item) {
      if (i == 1) {
        this.title = "开通新城市群";
        this.textContent = "扫码加好友推送城市申请"
        this.qrCodeUrl = require("@/assets/img/qr_code.png");
      } else {
        this.title = item.Name;
        this.textContent = "请用微信扫码进群"
        this.qrCodeUrl = item.Img;
      }
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.gang-up-container {
  width: 1040px;
  height: 676px;
  margin: 0 auto;
  padding: 20px;
  overflow: scroll;
  .context-container {
    display: flex;
    justify-content: space-between;
    .left-container {
      h3 {
        font-weight: bold;
        font-size: 18px;
        @include font_col3();
      }
      p {
        margin-top: 16px;
        font-size: 16px;
        @include font_col9();
      }
    }
  }
  .group-container {
    .title {
      margin: 12px 0;
      font-weight: bold;
      font-size: 18px;
      @include font_col3();
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-container {
        background: #fff;
        .el-input {
          width: 200px;
          @include font_col3();
        }
      }
    }
    .group-item {
      margin: 6px 0;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      @include item_bg_col();
      @include item_bd_color();
      @include font_col3();
      .el-button {
        width: 68px;
        height: 32px;
        line-height: 32px;
        padding: 0;
        background: transparent !important;
        color: #ff5555;
        // background: #ffffff;
        border-radius: 16px;
        border: 1px solid #ff5555;
        &:hover {
          background: #ff5555 !important;
          color: #ffffff;
        }
      }
    }
    .no-data {
      padding: 20px 0;
      border-radius: 8px;
      text-align: center;
      @include item_bg_col();

      img {
        width: 140px;
      }

      p {
        font-size: 16px;
        margin-top: 30px;
        @include font_col6();
        span {
          text-decoration: underline;
          color: #ff5555;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.custom-dialog {
  .el-dialog__body {
    text-align: center;
    .text {
      margin-top: 10px;
      padding: 8px;
      border-radius: 16px;
      @include font_col3();
      @include tag_bg_col();
      i {
        @include font_col3();
        margin-right: 5px;
      }
    }
  }
}
.input-container {
  .el-input__inner {
    @include item_bg_col();
    @include font_col3();
    border-radius: 0;
  }
}
</style>