<template>
    <div class="index-wrapper">
        <!-- 账号回收 -->
        <iframe id="jfsc" ref="jfsc" src="https://account.haoyouzhushou.com/activity/accountRecovery?from=mydjClient" width="100%" height="716px" frameborder="0"></iframe>
    </div>
</template>
  
<script>

export default {
};
</script>
  
<style lang="scss" scoped>
.index-wrapper {
    height: 716px;
    overflow: hidden;
    background: $white-col;
}
</style>